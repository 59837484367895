import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import ErrorPage from './pages/ErrorPage';
import Home from './pages/Home';
import Buy, { loader as buyLoader } from './pages/Buy';
import Sell from './pages/Sell';
import SellCard, { loader as sellCardLoader } from './pages/SellCard';
import YourCards from './pages/YourCards';
import YourBalance from './pages/YourBalance';
import AddCard from './pages/AddCard';
import PrivacyPolicy from './pages/PrivacyPolicy';

import { NotificationProvider } from './components/Notification';

import reportWebVitals from './reportWebVitals';
import './globalStyles.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/buy-gift-cards',
    element: <Buy />,
  },
  {
    path: '/buy-gift-cards/:storeId',
    element: <Buy />,
    loader: buyLoader,
  },
  {
    path: '/sell-gift-cards',
    element: <Sell />,
  },
  {
    path: '/sell-gift-cards/:storeId',
    element: <SellCard />,
    loader: sellCardLoader,
  },
  {
    path: '/sell-gift-cards/:storeId/:codeId',
    element: <SellCard />,
    loader: sellCardLoader,
  },
  {
    path: '/cards',
    element: <YourCards />,
  },
  {
    path: '/balance',
    element: <YourBalance />,
  },
  {
    path: '/cards/new',
    element: <AddCard />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
]);

const App = () => {
  return (
    <>
      <NotificationProvider>
        <RouterProvider router={router} />
      </NotificationProvider>
    </>
  );
};

const handleAuth0Redirect = (appState) => {
  if (appState?.returnTo) {
    window.location.replace(appState.returnTo);
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      connection: 'email',
    }}
    onRedirectCallback={handleAuth0Redirect}
  >
    <App />
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
