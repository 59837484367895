import React from 'react';
import { useLoaderData } from 'react-router-dom';

import Content from '../../components/Content';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { BreadcrumbHeader } from '../../components/PageHeader';

import { getAllStores, getAllCodes } from '../../api';

import CardTable from './CardTable';

export async function loader({ params }) {
  const stores = await getAllStores(true);
  const store = stores.find((s) => s.id === params.storeId);
  return { store };
}

const Buy = () => {
  const { store } = useLoaderData() ?? {};

  const [codes, setCodes] = React.useState([]);
  const [stores, setStores] = React.useState([]);

  const breadcrumbItems = React.useMemo(() => {
    const items = [
      { to: '/', label: 'Home' },
      { to: '/buy-gift-cards', label: 'Buy' },
    ];

    if (store) {
      items.push({ to: `/buy-gift-cards/${store.id}`, label: store.title });
    }

    return items;
  }, [store]);

  React.useEffect(() => {
    getAllCodes().then((res) => {
      const storesMap = {};
      const newCodes = [];

      for (let i = 0; i < res.length; i++) {
        const code = res[i];
        const { storeId, store, balance, price } = code;

        storesMap[storeId] = store;
        newCodes.push({
          ...code,
          storeTitle: store.title,
          discount: ((balance - price) / balance) * 100,
        });
      }

      setStores(Object.values(storesMap));
      setCodes(newCodes);
    });
  }, []);

  return (
    <>
      <Navbar />
      <Content>
        <BreadcrumbHeader
          items={breadcrumbItems}
          title="Discount Gift Cards"
          subtitleEl={
            <p className="mt-2 text-base text-gray-500">
              Shop with the{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href={process.env.REACT_APP_EXTENSION_URL}
                className="underline text-gray-700"
              >
                Cleo chrome extension
              </a>{' '}
              at these stores to unlock savings now
            </p>
          }
        />
        <CardTable codes={codes} stores={stores} currentStore={store} />
        {store && (
          <p className="mt-8 text-base text-gray-700">
            {store.descriptionForBuy ??
              `You can purchase ${store.title} Gift Cards here.`}
          </p>
        )}
      </Content>
      <Footer />
    </>
  );
};

export default Buy;
