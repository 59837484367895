import { useNavigate } from 'react-router-dom';
import { StarIcon } from '@heroicons/react/20/solid';

import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

import ExtensionScreenshot from '../../assets/images/extension-screenshot.webp';
import ExtensionScreenshot2 from '../../assets/images/extension-screenshot2.webp';
import DotPattern from '../../assets/images/dot-pattern.webp';
import DotPattern2 from '../../assets/images/dot-pattern2.webp';
import DotPattern3 from '../../assets/images/dot-pattern3.webp';
import YourBalance from '../../assets/images/your-balance-screenshot.webp';
import Cards from '../../assets/images/cards-screenshot.webp';

import FashionNovaImage from '../../assets/images/fashionnova.webp';
import AloImage from '../../assets/images/alo.webp';
import SkimsImage from '../../assets/images/skims.webp';
import KithImage from '../../assets/images/kith.webp';
import CultGaiaImage from '../../assets/images/cultgaia.webp';
import AimeImage from '../../assets/images/aime.webp';

const logos = [
  { src: FashionNovaImage, width: 158, height: 20 },
  { src: AloImage, width: 72, height: 48 },
  { src: SkimsImage, width: 158, height: 36 },
  { src: KithImage, width: 114, height: 48 },
  { src: CultGaiaImage, width: 162, height: 40 },
  { src: AimeImage, width: 150, height: 56 },
];

const Home = () => {
  const navigate = useNavigate();

  const handleOpenExtension = () => {
    const extensionUrl = process.env.REACT_APP_EXTENSION_URL;
    window.open(extensionUrl, '_blank');
    window.fbq('trackCustom', 'Clicked install extension button');
  };

  return (
    <>
      <Navbar />
      <div className="overflow-hidden bg-white pt-24 sm:pt-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pl-4 lg:py-48">
              <div className="relative z-[1] lg:max-w-3xl">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Save more with Cleo's Chrome extension
                </p>
                <p className="mt-6 text-xl leading-7 text-gray-500">
                  Cleo helps you find and buy discounted gift cards at checkout,
                  so you can stack savings and keep more money in your pocket.
                </p>
                <button
                  type="button"
                  className="rounded-md bg-indigo-600 mt-12 px-8 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={handleOpenExtension}
                >
                  Add to Chrome - It's Free
                </button>
                <div className="mt-9">
                  <div className="flex items-start flex-col sm:flex-row sm:items-center">
                    <div className="flex items-center">
                      {[0, 1, 2, 3, 4].map((rating) => (
                        <StarIcon
                          key={rating}
                          className="text-yellow-400 h-5 w-5 flex-shrink-0"
                          aria-hidden="true"
                        />
                      ))}
                    </div>
                    <p className="text-sm pt-2 h-7 leading-7 font-medium text-gray-900 sm:border-l sm:pt-0 sm:border-gray-300 sm:pl-5 sm:ml-5">
                      Chrome Store reviews
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative flex items-start justify-end lg:order-first">
              <img
                src={DotPattern}
                alt="Dot pattern"
                className="absolute top-0 right-0"
                width={404}
                height={392}
              />
              <img
                src={ExtensionScreenshot}
                alt="Extension screenshot"
                className="relative w-[48rem] max-w-none sm:w-[57rem] -top-8 -right-[13.8rem]"
                width={932}
                height={976}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
        <p className="text-base leading-6 font-medium tracking-wider uppercase text-gray-600 text-center mb-8">
          Supported Stores
        </p>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {logos.map(({ src, width, height }, index) => (
            <li
              key={index}
              className="h-28 flex col-span-1 divide-y divide-gray-200 bg-white text-center"
            >
              <img
                className="m-auto"
                src={src}
                alt="Supported store logo"
                width={width}
                height={height}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="overflow-hidden bg-white py-4">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex items-center">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  Turn your unwanted gift cards into cash
                </p>
                <p className="mt-4 text-lg leading-7 text-gray-500">
                  CleoCards.com is the marketplace for buying and selling gift
                  cards. List your unused or unwanted gift cards and get cash in
                  return.
                </p>
                <button
                  type="button"
                  className="rounded-md mt-6 leading-6 bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => navigate('/sell-gift-cards')}
                >
                  Sell Your Gift Cards
                </button>
              </div>
            </div>
            <img
              src={YourBalance}
              alt="Your balance screenshot"
              className="max-w-100 md:-ml-4 lg:-ml-0"
              width={636}
              height={647}
            />
          </div>
        </div>
      </div>
      <div className="overflow-hidden bg-white py-4">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex items-center">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  Keep your gift cards organized and never miss an expiration
                  date
                </p>
                <p className="mt-4 text-lg leading-7 text-gray-500">
                  With Cleo's "Your Cards" feature, you can upload your gift
                  cards, track their expiration dates, and keep them organized
                  in one place. Plus, get reminders when your cards are about to
                  expire.
                </p>
                <button
                  type="button"
                  className="rounded-md mt-6 leading-6 bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => navigate('/cards')}
                >
                  Get Started with "Your Cards"
                </button>
              </div>
            </div>
            <img
              src={Cards}
              alt="Cards screenshot"
              className="max-w-100 md:-ml-4 lg:-ml-0 lg:order-first"
              width={560}
              height={647}
            />
          </div>
        </div>
      </div>
      <div className="overflow-hidden bg-white pt-72 lg:py-24">
        <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
          <img
            src={ExtensionScreenshot2}
            alt="Extension screenshot 2"
            className="absolute z-10 -top-72 left-0 md:-top-60 md:left-20 lg:top-14 lg:left-10"
            width={469}
            height={462}
          />
          <div className="relative overflow-hidden bg-indigo-600 rounded-3xl flex items-center h-[28rem] px-6 md:px-10 md:h-[32rem] lg:ml-52">
            <img
              src={DotPattern2}
              alt="Dot pattern"
              className="hidden absolute top-0 right-0 sm:block"
              width={269}
              height={384}
            />
            <img
              src={DotPattern3}
              alt="Dot pattern"
              className="hidden absolute bottom-0 left-0 sm:block"
              width={269}
              height={192}
            />
            <div className="relative lg:ml-[30%] lg:max-w-lg">
              <p className="mt-2 text-3xl leading-10 font-extrabold tracking-tight text-white sm:text-4xl">
                Cleo + Coupon Codes
              </p>
              <p className="mt-6 text-lg leading-6 text-indigo-200">
                You can supercharge your savings by combining discounted gift
                cards with coupon codes.
              </p>
              <button
                type="button"
                className="rounded-md mt-6 leading-6 bg-white px-5 py-3 text-base font-medium text-indigo-600 shadow-sm hover:bg-neutral-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-100"
                onClick={handleOpenExtension}
              >
                Add to Chrome - It's Free
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
