import styled from 'styled-components';
import RTMMaskedInput from 'react-text-mask';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Input = styled.input`
  padding: 9px 13px;
  width: 100%;
  height: 38px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
`;

export const MaskedInput = styled(RTMMaskedInput)`
  padding: 9px 13px;
  width: 100%;
  height: 38px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
`;

export const InputLabel = styled.label`
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  margin-bottom: 4px;
`;
